<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="12">
          <v-data-table
              :loading="loading" 
              :items="allCommunities.results" 
              :server-items-length="allCommunities.pagination.total"
              :options.sync="options"
              :headers="headers"
          >
            <template v-slot:item.challengeName="{ item }">
              {{ item.communityName }}
            </template>
          </v-data-table>
          <div v-if="loading">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Community",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Communities",
    },
    loading:true,
    headers: [
      { text: 'Community Name', value: 'communityName', sortable: false },
      { text: 'Challenge Name', value: 'challengeName', sortable: false },
      { text: 'User Joined', value: 'numberOfJoined', sortable: false },
      { text: 'Feed Count', value: 'feedsCount', sortable: false },
    ],
    options: {
      itemsPerPage: 5,
      page: 1
    },
  }),
  methods: {
    validate() {},
    ...mapActions(["getCommunities"]),
    async reloadCommunities() {
      this.loading = true;
      await this.getCommunities({ limit: this.options.itemsPerPage, skip: (this.options.page - 1) * this.options.itemsPerPage });;
      this.loading = false;
    }
  },
  async created() {
    await this.reloadCommunities();
  },
  computed: {
    ...mapGetters(["allCommunities"]),
  },
  watch: {
    options: {
      async handler() {
        await this.reloadCommunities();
      },
      deep: true,
    }
  }
};
</script>